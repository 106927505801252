<template>
    <div class="withdraw">
        <!--第二模块  列表搜索-->
        <div class="withdraw-third">
            <div class="withdraw-third-top">
                <div class="withdraw-third0" style="margin-right: 28px">
                    <div class="withdraw-third0-title">提取订单号</div>
                    <el-input v-model="userOrder" placeholder="请输入订单号" clearable></el-input>
                </div>
                <div class="withdraw-third1" style="margin-right: 28px">
                    <div class="withdraw-third1-title">提现时间</div>
                    <el-date-picker v-model="receiptValue" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="withdraw-third-bottom">
                    <div class="withdraw-third1-bottom" @click="submitBtn">查询</div>
                </div>
            </div>

        </div>


        <!--表格-->
        <div class="withdraw-table">
            <el-table :data="withdrawList" style="width: 100%" :header-cell-style="{
                'text-align': 'center',
                background: '#FAFAFA ',
            }">
                <el-table-column prop="userTeamPriceLogId" label="id" align="center" width="120">
                </el-table-column>
                <el-table-column prop="userId" label="用户id" align="center" width="120">
                </el-table-column>
                <el-table-column prop="orderNum" label="提现订单号" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="提现金额" align="center" width="150">
                    <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
                </el-table-column>
                <el-table-column prop="" label="剩余金额" align="center" width="150">
                    <template slot-scope="scope"> ￥{{ scope.row.consumePrice }} </template>
                </el-table-column>
                <el-table-column prop="" label="提现手续费" align="center" width="150">
                    <template slot-scope="scope"> ￥{{ Number(scope.row.price) -  Number(scope.row.real_money) }} </template>
                </el-table-column>
                <el-table-column prop="" label="实际打款" align="center" width="150">
                    <template slot-scope="scope"> ￥{{ scope.row.real_money }} </template>
                </el-table-column>
                <el-table-column prop="bank_name" label="银行名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="bank_account" label="银行账号" align="center" width="200">
                </el-table-column>
                <el-table-column prop="bank_branch_name" label="支行名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="提现方式" align="center" width="120">
                    <template slot-scope="scope">
                        <div class="earningStatusColor">
                            {{
                                scope.row.payType == '1'
                                ? '微信'
                                : scope.row.payType == '2'
                                    ? '支付宝'
                                    : scope.row.payType == '3'
                                        ? '银行卡' : scope.row.payType == '4'
                                            ? '支付宝PC' : ''
                            }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="" label="提现审核状态" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="earningStatusColor" :style="{
                                                color: scope.row.withdrawStatus == 'PASS' ? '#29D647' : '#FF6A20',
                                            }">
                            {{
                                scope.row.withdrawStatus == 'PASS'
                                ? '同意'
                                : scope.row.withdrawStatus == 'REFUSE'
                                    ? '拒绝'
                                    : scope.row.withdrawStatus == 'UNDEFINED'
                                        ? '未处理' : ''
                            }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="createTime" label="提取时间" align="center" width="150">
                </el-table-column>
            </el-table>
        </div>

        <!--第四模块  分页模块-->
        <div class="withdraw-paging">
            <el-pagination @current-change="handleCurrentChange" :page-size="10" layout="prev, pager, next, sizes, jumper"
                :total="totalNum">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getWithdrawal } from '../api/index'

export default {
    data() {
        return {
            page: 1,
            limit: 10,
            withdrawList: [],
            totalNum: 0,
            //--------
            userId: '',
            userOrder: '',
            receiptValue: '',
            // 时间区域 起始
            payStartTime: '',
            // 时间区域 完结
            payEndTime: '',
        }
    },
    created() {
        this.getWithdrawalMet()
    },
    methods: {
        getWithdrawalMet() {
            let params = {
                page: this.page,
                orderNum: this.userOrder,
                createStartTime: this.payStartTime,
                createEndTime: this.payEndTime
            }

            getWithdrawal(params).then(res => {
                if (res.data.code == 200) {
                    this.withdrawList = res.data.content.list
                    this.totalNum = Number(res.data.content.totalNum)
                }
            })
        },
        submitBtn() {
            // 输入框搜索值 请输入用户ID/手机号/订单号
            if (this.receiptValue != null) {
                this.payStartTime = this.receiptValue[0]
                this.payEndTime = this.receiptValue[1]
            } else {
                this.payStartTime = ''
                this.payEndTime = ''
            }


            this.getWithdrawalMet()
        },
        // 获取当前页码
        handleCurrentChange(val) {
            this.page = val
            this.getWithdrawalMet()
        },

    }
}
</script>

<style lang="less" scoped>
.withdraw {
    margin: 20px;

    .withdraw-third {
        width: 100%;
        background: #fff;
        margin-top: 10px;
        padding: 24px;
        box-sizing: border-box;

        .withdraw-third-top {
            height: 40px;
            display: flex;
            align-items: center;
            width: 100%;

            .withdraw-third0 {
                margin-right: 30px;
                cursor: pointer;
                display: flex;
                height: 40px;
                align-items: center;

                .withdraw-third0-title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 10px;
                }

                /deep/ .el-input--suffix .el-input__inner {
                    padding-right: 15px;
                }

                /deep/ .el-input {
                    width: 170px;
                }

                /deep/ .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                }

                /deep/ .el-input__icon {
                    line-height: 32px;
                }

            }

            .withdraw-third1 {
                display: flex;
                height: 40px;
                align-items: center;

                .withdraw-third1-title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 10px;

                    .prefix-icon-class {
                        display: none;
                    }
                }

                /deep/ .el-date-editor .el-range-separator {
                    width: 40px;
                    line-height: 27px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                }

                /deep/ .el-date-editor .el-range-input {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                }

                /deep/ .el-input__inner {
                    height: 32px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                }

                /deep/ .el-input__icon {
                    line-height: 25px;
                }

                /deep/ .el-input__inner {
                    width: 280px;
                }
            }

            .withdraw-third-bottom {
                display: flex;

                .withdraw-third1-bottom {
                    cursor: pointer;
                    width: 60px;
                    height: 32px;
                    background: #29d647;
                    border-radius: 5px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 32px;
                    text-align: center;
                }
            }
        }


    }

    .withdraw-table {
        cursor: pointer;

        /deep/ .el-table th.el-table__cell>.cell {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #565656;
        }

        /deep/ .el-table {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #717171;
        }


        background: #fff;

        .earningStatusColor {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
    }

    .withdraw-paging {
        height: 102px;
        width: 100%;
        background: #ffffff;
        padding-right: 30px;

        /deep/ .el-pagination {
            display: flex;
            justify-content: flex-end;
            padding-top: 20px;
        }

        /deep/ .el-pager li {
            width: 32px;
            height: 32px;
            padding: 0;
            line-height: 32px;
            margin-right: 8px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
        }

        /deep/ .el-pager li.active {
            background: #eafbed;
            border-radius: 4px;
            color: #29d647;
        }

        /deep/ .el-pager li:hover {
            color: #29d647;
        }

        /deep/ .el-pagination .btn-prev {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            margin-right: 8px;
        }

        /deep/.el-pagination .btn-next {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            margin-right: 8px;
        }

        /deep/ .el-pagination button:hover {
            color: #29d647;
        }
    }
}
</style>
